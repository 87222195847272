@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css');
@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=latin-ext');
html, body {
  width: 100%;
}

body {
  color: #26262C;
  font-family: 'Raleway', sans-serif;
  padding: 0 62px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
}

#root {
    min-height: 100%;
    position: relative;
    width: 100%;
}

body * {
  font-family: 'Raleway', sans-serif;
}

h1, h2 {
  word-wrap: normal;
}

h1 {
  font: 900 100px/100px 'Raleway', sans-serif;
  margin: 0 0 40px;
}

h2 {
  font: 900 60px/60px 'Raleway', sans-serif;
  margin: 0 0 40px;
}

h4 {
  font: 900 24px/34px 'Raleway', sans-serif;
}

p {
  font: 18px/28px 'Raleway', sans-serif;
  word-wrap: break-word;
}

.bigText {
  font: 36px/46px 'Raleway', sans-serif;
  margin: 40px 0;
}

img {
  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.blueFont {
  color: #003BFF;
}

.blackBlock {
  background: #26262C;
}

.redBlock {
  background: #FF4949;
}

.redText {
  font: 900 24px/29px 'Raleway', sans-serif;
  color: #FF4949;
  margin: 0 0 20px;
}

.greyBlock {
  background: #e6e8eb;
}

.blueBlock {
  background: #003BFF;
}

.blueGreyBlock {
  background-image: linear-gradient(to bottom, #003BFF 0%,#003BFF 280px, #e6e8eb 0%, #e6e8eb 100%);
}

.blackBlock, .redBlock, .blueBlock, .redBlock .pageName, .nextArticle {
  color: #fff;
}

.header {
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  width: 100%;
  padding: 0 62px;
  box-sizing: border-box;
  line-height: 0;
}

body::after {
  content: '';
  width: 100%;
  height: 62px;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
}

.logo {
  width: 140px;
  margin: auto 0 20px;
}

.backBtn {
  position: absolute;
  top: 40px;
  left: 35px;
}

.fullScreenBlock {
  min-height: 100vh;
  box-sizing: border-box;
  /*padding: 90px 0 62px;*/
  position: relative;
  overflow: hidden;
}

.fullScreenBlock:after{
  content:'';
  min-height:inherit;
  font-size:0;
}

.slideOrder {
  font: 900 24px/28px 'Raleway', sans-serif;
  width: 54px;
  margin: 0 0 20px;
}

.slideOrder > div {
  border-top: 2px solid #000;
  font: inherit;
  color: #fff;
  -moz-text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  -webkit-text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@supports (-webkit-text-stroke: 1px #000) {
  .slideOrder > div {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 1px #000;
    text-stroke: 1px #000;
  }
}

.fixHeight {
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  line-height: 0;
  height: 100%;
}

.slide {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.slide img {
  width: 100%;
  height: 100%;
}

.rightSide .slide {
  padding: 210px 16.67% 90px;
  box-sizing: border-box;
}

.leftSide .slide {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mouse_wrap {
  display: inline-block;
  position: absolute;
  bottom: 111px;
  opacity: 0;
}

section.active .mouse_wrap {
  animation: 1s ease 0s normal forwards 1 fadeinMouse;
}

@keyframes fadeinMouse {
    0% { opacity:0; }
    100% { opacity:1; }
}

@-webkit-keyframes fadeinMouse{
    0% { opacity:0; }
    100% { opacity:1; }
}

.mouse {
  position: relative;
  overflow: hidden;
  width: 20px;
  height: 30px;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  margin: 0 auto;
}

.greyBlock .mouse {
  border-color: #26262C;
}

.rolltext {
  font: normal 10px/28px 'Raleway', sans-serif;
  color: #FFFFFF;
}

.mouse .roll {
  position: absolute;
  top: 7.5px;
  left: 50%;
  width: 1px;
  margin-left: -1px;
  height: 5px;
  background: #fff;
  -webkit-animation: mouseScroll .8s cubic-bezier(.7,0,.3,1) infinite alternate;
  animation: mouseScroll .8s cubic-bezier(.7,0,.3,1) infinite alternate;
  z-index: 1;
}

.greyBlock .mouse .roll {
  background: #26262C;
}

.greyBlock .rolltext {
  color: #26262C;
}

@keyframes mouseScroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(7.5px);
  }
}

.leftSide {
    position: absolute;
    bottom: 0;
    width: 49.392%;
    top: 0;
    z-index: 1;
}

.rightSide {
    width: 50.608%;
    /*position: absolute;*/
    right: 0;
    top: 0;
    margin: 0 0 0 auto;
}

#menu, #menu a {
  width: 26px;
  height: 14px;
  display: block;
  pointer-events: all;
  cursor: pointer;
}

.is-zoomed-out #menu a {
  pointer-events: none;
}

#menu span {
  display: block;
  width: 21px;
  height: 2px;
  margin-bottom: 4px;
  position: relative;
  background: #000;
  z-index: 1;
  -webkit-transition: width 200ms ease 0s;
  -moz-transition: width 200ms ease 0s;
  -o-transition: width 300ms ease 0s;
  transition: width 300ms ease 0s;
}

#menu span:nth-of-type(2) {
  margin-left: auto;
}

#menu:hover span {
  width: 26px;
}

.book {
  overflow: hidden;
  padding: 0;
  height: 100%;
}

.menuPage > .smallerFrameBlock {
  padding-left: 0;
  padding-right: 0;
  padding-top: 90px;
}

.is-zoomed-out .menuPage > .smallerFrameBlock {
  padding: 180px 8.435% 40px;
  padding: 0 8.435% 40px;

}

.book--zoom-out {
    overflow: scroll;
}

.book__slider {
    display: flex;
    visibility: unset;
    transform: perspective(600px);
    position: relative;
    width: 800%;
    transform-origin: 50% 50%;
    padding-top: 90px;
    height: 100%;
    box-sizing: border-box;
}

.loaded.home_page .book__slider{
  padding-top: 0;
}

.book--zoom-out .book__slider {
    height: 1000px;
    height: 1240px;
    justify-content: space-between;
    flex-wrap: wrap;
    perspective: 600px;
    transition: none;
    transform: scale(.5);
    transform-origin: 0 0;
    width: 200%;
    height: 1284px;
    padding-top: 360px;
    width: calc(200vw - 500px);
    width: calc(200vw - 68.28%);
    width: calc(200vw - 248px);
    padding: 360px 7.685% 0;
    margin: 0 auto;
    box-sizing: border-box;
}

.page_item {
    position: relative;
    min-width: 10%;
    transition: box-shadow .15s ease-out;
    width: 10%;
    z-index: 1;
    display: block;
}

.page_item, .page_item .view__page {
  min-height: 100vh;
  min-height: 100%;
}

.book__slider .page_item:nth-of-type(2n + 1) .view__page {
  margin-left: auto;
}

.book__slider .view__page {
  max-width: 1316px;
  overflow: visible;
  position: relative;
}

.view__page-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.page-item_title {
  position: absolute;
  top: -96px;
}

.is-zoomed-out .page_item {
    backface-visibility: hidden;
    height: 636px;
    max-height: none;
    margin-bottom: 238px;
    min-height: 0;
    min-width: 0;
    width: calc(50% - 16px);
}

.is-zoomed-out .page_item .view__page {
  width: 100%;
  height: 100%;
}

.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.page__mask {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5000;
  cursor: pointer;
}

.page_title {
  cursor: pointer;
  font: 900 48px/56px 'Raleway', sans-serif;
  color: #D0D3DB;
  transition: all 200ms ease-in-out;
}

.page_title:hover, .menu_title:hover {
  color: #003BFF;
}

.page_title:hover span {
  text-shadow: -1px -1px 0 #003BFF, 1px -1px 0 #003BFF, -1px 1px 0 #003BFF, 1px 1px 0 #003BFF;
}

.page_title-inner-container {
  width: 100%;
}

.menu_title:hover span {
  -moz-text-shadow: -2px -2px 0 #003BFF, 2px -2px 0 #003BFF, -2px 2px 0 #003BFF, 2px 2px 0 #003BFF;
  -webkit-text-shadow: -2px -2px 0 #003BFF, 2px -2px 0 #003BFF, -2px 2px 0 #003BFF, 2px 2px 0 #003BFF;
  text-shadow: -2px -2px 0 #003BFF, 2px -2px 0 #003BFF, -2px 2px 0 #003BFF, 2px 2px 0 #003BFF;
}

.page_title span {
  font: inherit;
  margin: 0 10px 0 0;
  color: #fff;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #D0D3DB, 1px -1px 0 #D0D3DB, -1px 1px 0 #D0D3DB, 1px 1px 0 #D0D3DB;
  transition:all 200ms ease-in-out;
}

.menu_title span {
  font: inherit;
  margin: 0 20px 0 0;
  color: #fff;
  text-transform: uppercase;
  -moz-text-shadow: -2px -2px 0 #D0D3DB, 2px -2px 0 #D0D3DB, -2px 2px 0 #D0D3DB, 2px 2px 0 #D0D3DB;
  -webkit-text-shadow: -2px -2px 0 #D0D3DB, 2px -2px 0 #D0D3DB, -2px 2px 0 #D0D3DB, 2px 2px 0 #D0D3DB;
  text-shadow: -2px -2px 0 #D0D3DB, 2px -2px 0 #D0D3DB, -2px 2px 0 #D0D3DB, 2px 2px 0 #D0D3DB;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.menu_title {
  position: absolute;
  cursor: pointer;
  font: 900 48px/56px 'Raleway', sans-serif;
  color: #D0D3DB;
  transition: all 200ms ease-in-out;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  -webkit-transition:all 200ms ease-in-out;
  -moz-transition:all 200ms ease-in-out;
  -o-transition:all 200ms ease-in-out;
  transition:all 200ms ease-in-out;
}

@supports (-webkit-text-stroke: 1px #D0D3DB) {
  .page_title span {
    text-shadow: none;
    -webkit-text-stroke: 1px #D0D3DB;
    text-stroke: 1px #D0D3DB;
  }
  .page_title:hover span {
    text-shadow: none;
    text-stroke: 1px #003BFF;
    -webkit-text-stroke: 1px #003BFF;
  }
}

@supports (-webkit-text-stroke: 3px #D0D3DB) {
  .menu_title span {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 3px #D0D3DB;
    text-stroke: 3px #D0D3DB;
  }
  .menu_title:hover span {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 3px #003BFF;
    text-stroke: 3px #003BFF;
  }
}

.is-zoomed-out .preview-footer  {
    display: block;
}

.preview-footer {
  display: none;
  padding-top: 0;
}

.is-zoomed-out .preview-footer  {
    padding-top: 40px
}

.page--is-active {
    display: block;
    height: auto;
    overflow: hidden;
}

.book.book--zoom-out .page_item header {
  display: none;
}

.animation__container {
    display: flex;
    left: 62px;
    overflow: hidden;
    position: fixed;
    top: 90px;
    transform: perspective(600px);
    transition: transform .2s ease-out;
    width: calc(100% - 124px);
    z-index: 9;
}

@media (max-width: 768px) {
  .animation__container {
    top: 60px;
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .animation__container {
    top: 60px;
  }
}

.is-hidden {
  display: none;
}

.animation__container.is-hidden {
    display: none!important;
}

.animation__container {
    transition-duration: .25s;
    transition-delay: 50ms;
}

.animation__inner.page_item {
  height: calc(100vh - 90px)!important;
  transition: height .2s ease-in-out;
  overflow: scroll;
  width: 100%!important;
}

@media (max-width: 768px) {
  .animation__inner.page_item {
    height: calc(100vh - 60px)!important;
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .animation__inner.page_item {
    height: calc(100vh - 60px)!important;
  }
}

@media (min-width: 1180px){
  .book--zoom-out .book__slider {
    height: 1284px;
    padding-top: 360px;
    width: calc(200vw - 248px);
    padding: 360px 13.195% 0;
    margin: 0 auto;
    box-sizing: border-box;
 }
  .view__page {
    transform: none;
    perspective: none;
    backface-visibility: visible;
  }
  .container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;/*
    padding-left: 200px;
    padding-right: 100px;*/
  }
}

@media (min-width: 1100px) {
  .book .book__slider .page_item {
    height: 750px;
  }
}


.container .frameBlock {
  padding: 60px 8.435%;
  position: relative;
}

.smallerFrameBlock {
  padding: 40px 8.435%;
}

.pageName, .footer, .flexBox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.flexBox, .footer {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.header, .partnerPage .flexBox {
  justify-content: space-between;
}

.header, .homePage .greyBlock, .homePage .blackBlock {
  align-items: center;
}

.pageName {
  color: #D0D3DB;
  font: 900 18px/21px 'Raleway', sans-serif;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -ms-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  position: absolute;
  top: 120px;
  left: calc(100% - 20px);
}

.pageName:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 1px;
  background: #D0D3DB;
  margin: 1px 0 0;
}

.redBlock .pageName:before {
  background: #fff;
}

.pageName span {
  color: #e6e8eb;
  padding: 0 9px 0 10px;
  text-transform: uppercase;
  -moz-text-shadow: -1px -1px 0 #D0D3DB, 1px -1px 0 #D0D3DB, -1px 1px 0 #D0D3DB, 1px 1px 0 #D0D3DB;
  -webkit-text-shadow: -1px -1px 0 #D0D3DB, 1px -1px 0 #D0D3DB, -1px 1px 0 #D0D3DB, 1px 1px 0 #D0D3DB;
  text-shadow: -1px -1px 0 #D0D3DB, 1px -1px 0 #D0D3DB, -1px 1px 0 #D0D3DB, 1px 1px 0 #D0D3DB;
}

@supports (-webkit-text-stroke: 1px #D0D3DB) {
  .pageName span {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 1px #D0D3DB;
    text-stroke: 1px #D0D3DB;
  }
}

.redBlock .pageName span {
  color: #FF4949;
  -moz-text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  -webkit-text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

@supports (-webkit-text-stroke: 1px #fff) {
  .redBlock .pageName span {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 1px #fff;
    text-stroke: 1px #fff;
  }
}

.blueBlock .pageName {
  color: #0008A3;
}

.blueBlock .pageName::before {
  background: #0008A3;
}

.blueBlock .pageName span {
  color: #003BFF;
  -moz-text-shadow: -1px -1px 0 #0008A3, 1px -1px 0 #0008A3, -1px 1px 0 #0008A3, 1px 1px 0 #0008A3;
  -webkit-text-shadow: -1px -1px 0 #0008A3, 1px -1px 0 #0008A3, -1px 1px 0 #0008A3, 1px 1px 0 #0008A3;
  text-shadow: -1px -1px 0 #0008A3, 1px -1px 0 #0008A3, -1px 1px 0 #0008A3, 1px 1px 0 #0008A3;
}

@supports (-webkit-text-stroke: 1px #0008A3) {
  .blueBlock .pageName span {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 1px #0008A3;
    text-stroke: 1px #0008A3;
  }
}

.blockGroup:not(:last-child) {
  margin: 0 0 102px;
}

.blockLeftSide {
  flex: 0 1 27%;
  margin: 0 auto 0 0;
}

.blockRightSide {
 /* padding: 0 0 0 30.44%;*/
  flex: 0 1 69.57%;
  margin: 0 0 0 auto;
}

.homePage .greyBlock .blockRightSide {
  flex: 0 1 39.125%;
  padding: 20px 0 0;
}

.redLine::before, .redLineAfter::after {
  content: '';
  display: block;
  width: 68px;
  height: 6px;
  background: #FF4949;
}

.redLineAfter::after {
  margin: 10px 0 0;
}

.blockRightSide img.bigImg {
  max-width: 79.25%;
  margin: 79px auto;
  display: block;
}

.view__page-container img.bigImg {
  margin: 150px auto;
}

.ourPartners {
  margin: 0 -4.8% -40px;
}

.ourPartners > div {
  flex: 0 0 24.7%;
  margin: 0 4.317% 40px;
}

.blueTitle {
  font: 900 24px/28px 'Raleway', sans-serif;
  color: #003BFF;
}

.subGroup p {
  margin: 10px 0;
}

.subGroup {
  margin: 40px 0;
}

/*remove in future?*/
.ourPartners > div {
  position: relative;
  font: 900 24px/28px 'Raleway', sans-serif;
}

.ourPartners > div::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.ourPartners > div > a, .ourPartners > div > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #D0D3DB;
  color: #fff;
  line-height:100%;
  height:100%;
  text-align: center;
  display: flex;
  -ms-align-items: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.partnerRightCol {
  flex: 0 0 49.27%;
}

.partnerLeftCol {
  flex: 0 0 45%;
}

.partnerPhoto {
  width: 100%;
}

/**/
.homePage .blackBlock > div, .homePage .greyBlock > div {
  padding: 0 8.435%;
  box-sizing: border-box;
  width: 100%;
}

.homePage .blackBlock h2, .homePage .blackBlock h4, .homePage .greyBlock h1, ol.ourValuesList li:last-of-type {
  margin: 0;
}

.homePage .blackBlock h4:not(:last-of-type) {
  font-weight: normal;
}

.homePage .blackBlock h2 {
  margin: 20px 0;
}

.typeWrapper h2 .textFadeInAnimated {
  opacity: 0;
  animation-delay: 1s;
  animation: textFadeIn 0.3s forwards; /* forwards (animation-fill-mode) retains the style from the last keyframe when the animation ends */
}

@keyframes textFadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

.footer {
  max-width: 1094px;
  margin: 40px auto 60px;
  padding: 0 24px;
  height: 180px;
}

.footer > div {
  margin: -9px 2% 0 0;
  flex: 1 1 23%;
}

.footer > div.locationsGroup {
  /*flex: 1 1 50%;*/
  display: none;
}

.footer > .copyright {
  color: #5A5A5A66;
  margin: -9px 0 0;
  text-align: right;
}

.footer h5 {
  font: 900 14px/28px 'Raleway', sans-serif;
  margin: 0;
}

.footer p {
  font: 14px/26px 'Raleway', sans-serif;
  margin: 0;
}

.locationsGroup > div{
  /*display: flex;*/
}

.locationsGroup p {
 /* max-width: 150px;*/
  padding: 0 74px 0 0;
  /*margin: 0 0 20px;*/
}

.locationsGroup p:last-of-type{
  padding: 0;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

ol.ourValuesList {
  counter-reset: item;
  list-style-type: none;
  padding: 0;
  margin: 80px 0 0;
}

ol.ourValuesList li::before {
  content: counter(item, decimal-leading-zero) " ";
  counter-increment: item;
  position: absolute;
  top: 0;
  left: 0;
  font: 900 60px/36px 'Raleway', sans-serif;
  color: #FF4949;
  -moz-text-shadow: -1px -1px 0 #26262C, 1px -1px 0 #26262C, -1px 1px 0 #26262C, 1px 1px 0 #26262C;
  -webkit-text-shadow: -1px -1px 0 #26262C, 1px -1px 0 #26262C, -1px 1px 0 #26262C, 1px 1px 0 #26262C;
  text-shadow: -1px -1px 0 #26262C, 1px -1px 0 #26262C, -1px 1px 0 #26262C, 1px 1px 0 #26262C;
}

@supports (-webkit-text-stroke: 1px #26262C) {
  ol.ourValuesList li::before {
    -moz-text-shadow: none;
    -webkit-text-shadow: none;
    text-shadow: none;
    -webkit-text-stroke: 1px #26262C;
    text-stroke: 1px #26262C;
  }
}

ol.ourValuesList li {
  padding: 0 0 0 111px;
  margin: 0 0 40px;
  position: relative;
}

ol.ourValuesList li > div {
  font: 900 36px/36px 'Raleway', sans-serif;
  margin: 0 0 20px;
}

.ourCollective {
  margin: 0 -3.291% -80px;
  justify-content: center;
}

.ourCollective > div {
  flex: 0 0 27.102%;
  margin: 0 3.1157% 80px;
}

.ourCollective .bioName {
  font: 900 24px/26px 'Raleway', sans-serif;
  margin: 20px 0 10px;
  color: #000000;
}

.link_to_bio, .link_to_partner {
  cursor: pointer;
}


@media (min-width: 769px) {
  .ourCollective > div {
    flex: none;
  }
  .ourCollective .bio-img {
    max-width: 250px;
    max-height: 250px;
  }
}

.bioPhoto, .backBtn {
  line-height: 0;
  overflow: hidden;
}

.bioBlock .bioPhoto {
  flex: 0 0 39.123%;
  margin: 0 40px 40px 0;
}

.bioBlock .bioAbout {
  flex: 0 0 calc(60.877% - 40px);
}

.bioAboutText {
  margin: 80px 0 0;
}

.bioPage .ourCollective {
  margin-top: 120px;
}

.bioBlock .bioName {
  font: 900 36px/40px 'Raleway', sans-serif;
  color: #fff;
  margin: 117px 0 10px;
}

.bioPage, .partnerPage {
  position: relative;
}

.backBtn svg {
  font-size: 21px;
  line-height: 0;
  color: #fff;
  cursor: pointer;
}

.backBtn.blackStyle svg {
  color: #000;
}

.bioPhoto img {
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ourCollective .bioPhoto:hover img {
  transform: scale(1.2);
}

.postsList .postItem a {
  color: #26262C;
}

.postsList .postTitle {
  font: 900 24px/28px 'Raleway', sans-serif;
  margin: 0 0 10px;
  -webkit-transition:all 500ms ease-in-out;
  -moz-transition:all 500ms ease-in-out;
  -o-transition:all 500ms ease-in-out;
  transition:all 500ms ease-in-out;
}

.postsList .postItem:hover .postTitle {
  color: #003BFF;
}

.postsList .postItem {
  margin: 0px 0 32px;
  cursor: pointer;
}

.postsList .postInfo {
  font: normal 14px/24px 'Raleway', sans-serif;
}

.posts .leftSide {
 width: 50%;
}

.posts .rightSide {
/*  padding: 120px 7.295% 120px 9.651%;
*/  padding: 120px 7.295% 120px 59.043%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.posts .rightSide::-webkit-scrollbar {
  display: none;
}

.book__slider .journalPage .posts .rightSide {
  max-height: 100%;
}

.book__slider .journalPage .posts .postImage {
  max-height: 750px;
}

.postImageInner {
  height: 100%;
}

.articleImageInner, .postImageInner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.postImage {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.postImage.visible {
  opacity: 1;
  visibility: visible;
  height: 100%;
  top: 0;
}

blockquote {
  font: normal 60px/64px 'Raleway', sans-serif;
  margin: 40px 0 40px -20px;
  position: relative;
  padding: 0 0 0 20px;
}

blockquote::before {
  content: '';
  position: absolute;
  width: 6px;
  height: 100%;
  background: #FF4949;
  left: 0;
  top: 0;
}

.articleImageInner.smallImg {
  min-height: unset;
  max-height: 400px;
  height: 400px;
  height: calc(0.4445*100vh);
}

.shareButtons {
  color: #FF4949;
  margin: 80px 0 0;
  line-height: 0;
}

.shareButtons svg {
  width: 100%;
  height: 100%;
}

.shareTwitter {
  width: 32px;
  height: 26px;
  margin: 0 18.45px 0 0;
}

.shareFacebook {
  width: 14px;
  height: 26px;
}

.nextArrow {
  width: 95px;
  height: 21px;
  color: #FF4949;
}

.nextArrow svg {
  width: 100%;
  height: 100%;
}

.nextArticle a {
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 0 71px;
}

.nextArticle h2 {
  margin: 0;
  flex: 0 0 77%;
  font: 900 30px/35px 'Raleway', sans-serif;
}

/*Multiscroll*/
.principles .leftSide {
  height: 100%;
  /*height: 100vh;*/
  bottom: 0;
  top: unset;
}

.principles > div, .principles .rightSide, .principles .slide {
  height: 100%;
  /*height: 100vh;*/
}

.principles .rightSide, .sectionWrap {
  position: relative;
  height: 100%;
}

.principles .rightSide.animated, .principles .leftSide.animated, .sectionWrap.animated {
  transition: all 0.5s cubic-bezier(.77, 0, .175, 1);
}

.principles .rightSide .slide:nth-of-type(1) {
  top: 0;
}

.principles .rightSide .slide, .principles .leftSide .slide {
  position: absolute;
}

.sliderBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage--footer {
  visibility: hidden;
  position: fixed;
  width: 100%;
  bottom: -300px;
  transition: all 0.3s ease 0s;
}

.homepage--footer.active {
  visibility: visible;
  bottom: 0;
}

body.is-zoomed-out .loader{
  display: none;
}

body.has-no-tile-expanded .animated.fadeInUp,
body.has-no-tile-expanded .mouse_wrap,
.homePage--animated-once .animated.fadeInUp,
.homePage--animated-once .mouse_wrap {
  animation-delay: 0ms !important;
  animation-duration: 0ms !important;
  opacity: 1;
  animation: unset;
}

body.is-zoomed-out .view__page .mouse_wrap {
  display: none;
}

body.is-zoomed-out .view__page.view__page--active .mouse_wrap {
  display: block;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 10;
    opacity: 1;
    transition: transform 1500ms cubic-bezier(1, 0, 0, 1) 0ms,
      opacity 250ms cubic-bezier(1, 0, 0, 1) 0ms,
      visibility 1500ms cubic-bezier(1, 0, 0, 1) 0ms,
      width 1500ms cubic-bezier(1, 0, 0, 1) 0ms,
      height 1500ms cubic-bezier(1, 0, 0, 1) 0ms,
      top 1500ms cubic-bezier(1, 0, 0, 1) 0ms,
      left 1500ms cubic-bezier(1, 0, 0, 1) 0ms;
}

.loader .color {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #26262C;
}

.loaded .loader {
  width: calc(100% - 124px);
  height: calc(100% - 152px);
  top: 90px;
  left: 62px;
  visibility: hidden;
}

.loaded .loader .color {
  -webkit-transition: background 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  -moz-transition: background 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  transition: background 500ms cubic-bezier(1, 0, 0, 1) 0ms;
}

.menuPage {
  height: 100%;
}

.homePage {
  position: relative;
  height: calc(100vh - 90px);
  min-height: 100%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .homePage {
    height: calc(100vh - 60px);
  }
}

.homePage section {
    position: absolute;
    top: 100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100%;
}

.homePage section:nth-of-type(1) {
  top: 0;
}

.homePage section:nth-of-type(2) {
  top: 100%;
}

.homePage section:nth-of-type(3) {
  top: 200%;
}

/*Multiscroll*/
.playAudio {
  width: 60px;
  margin: 0 20px 0 0;
  cursor: pointer;
}

.wave {
  flex: 1 1 calc(100% - 60px);
  max-width: 420px;
  align-self: flex-end;
  max-width: 420px;
  min-width: 270px;
  width: calc(100% - 60px);
  align-self: flex-end;
}

.videoOpen {
  width: 120px;
  position: absolute;
  top: -60px;
  right: 32px;
  cursor: pointer;
  z-index: 9;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 11;
    text-align: right;
}

.ReactModal__Content.ReactModal__Content--after-open {
    width: 800px;
    max-width: 100%;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.closeBtn {
  display: inline-block;
  cursor: pointer;
  margin: 0 0 10px;
  background: #FF4949;
  color: #fff;
  font-weight: 900;
  padding: 5px 10px;
}

.bioPage {
  margin-top: 0 !important;
}

.journalPage .posts {
  width: 100%;
  transition: all .5s cubic-bezier(.7,0,.3,1);
}

.articleImageInner  {
  height: calc(44vh);
  max-height: calc(44vh);
  transition: all 1s cubic-bezier(.7,0,.3,1);
}

@media (max-width: 835px) {
  .journalPage .posts.animated_ms {
    padding-left: 100%;
  }
}

.book__slider .collectivePage .blueBlock {
  height: 100vh;
}

.animation__container .collectivePage .collectivePage__intro {
  /* min-height: calc(100vh - 90px - 60px - 120px); */
}
.animation__container .greyBlock {
  min-height: calc(50vh - 90px - 60px - 120px);
}

@media (max-width: 768px) {
  /* .animation__container .collectivePage .collectivePage__intro, */
  .animation__container .greyBlock {
    min-height: calc(100vh - 60px - 25px - 120px);
  }
}

.animation__container .collectivePage .smallerFrameBlock {
  margin-top: 0;
}

@media (max-width: 1600px) {
  .posts .rightSide {
    padding: 120px 7.295% 120px 55%;
  }
  .rightSide .slide {
    padding: 15% 15% 90px;
  }
  .rightSide .slide h2 {
    font: 900 45px/45px 'Raleway', sans-serif;
  }
  h1 {
    font: 900 90px/90px 'Raleway', sans-serif;
  }
}

@media (max-width: 1112px) {
  h1 {
    font: 900 80px/80px 'Raleway', sans-serif;
  }
  .blockLeftSide, .blockRightSide, .homePage .greyBlock .blockRightSide, .partnerLeftCol, .partnerRightCol {
    flex: 0 1 100%;
  }
  blockquote {
    font: normal 40px/44px 'Raleway', sans-serif;
  }
  .partnerRightCol {
    margin: 40px 0 0;
  }
  .partnerRightCol p:nth-child(1) {
    margin-top: 0;
  }
  .is-zoomed-out .page_item {
    height: 650px;
  }
}

@media (max-width: 1024px) {
  body {
    padding: 0;
  }

  .header.flexBox {
    padding: 0 25px 0 24px;
  }

  body::after  {
    display: none;
  }

  .mouse_wrap {
    bottom: 100px;
  }

  .loaded .loader {
    width: 100%;
    height: 100%;
    left: 0;
  }

  .book--zoom-out .book__slider {
    height: 1000px;
  }

  .is-zoomed-out .page_item {
    height: 640px;
    margin-bottom: 180px;
    min-height: 0;
    max-height: none;
  }

  .container {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
  }

  .book--zoom-out .book__slider {
    width: 200%;
  }

  .animation__container {
    left: 0;
    width: 100%;
  }
}

@media (max-width: 834px) {
  .container .frameBlock, .smallerFrameBlock {
    padding: 80px 25px 80px 24px;
  }
  .container .nextArticle {
    padding: 40px 25px 40px 24px;
  }
  .book--zoom-out .book__slider {
    padding: 360px 50px 0 48px;
  }
  .nextArticle a {
    padding: 0;
  }
  .footer {
    margin: 0 auto 60px;
  }
  .footer > .copyright {
    flex: 1 1 100%;
    text-align: left;
    margin: 20px 0;
  }
  .blueGreyBlock {
    background-image: linear-gradient(to bottom, #003BFF 0%,#003BFF 300px, #e6e8eb 0%, #e6e8eb 100%);
  }
  blockquote {
    font: normal 40px/44px 'Raleway', sans-serif;
    margin: 20px 0;
  }
  .nextArticle h2 {
    flex: 0 0 100%;
    margin: 0 0 49px;
    font: 900 36px/36px 'Raleway', sans-serif;
  }

  .pageName {
    top: 80px;
  }
  .posts.fullScreenBlock {
    height: auto;
  }
  .posts .rightSide, .posts .leftSide {
    position: relative;
  }
  .posts .rightSide {
    padding: 80px 25px 80px 24px;
    max-height: unset;
  }
  .posts .leftSide {
    height: calc(0.3*100vh);
    width: 100%;
  }
  .journalPage .pageName {
    top: 80px;
  }
}

@media (max-width: 812px) {
  .pageName {
    font: 900 12px/15px 'Raleway', sans-serif;
    left: calc(100% - 11px);
  }
  .blockGroup:not(:last-child) {
    margin: 0 0 62px;
  }
  .sliderBackground {
    opacity: 0.85;
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .mouse_wrap {
    bottom: 0px;
  }
  h1 {
    font: 900 70px/70px 'Raleway', sans-serif;
  }
  h1 br {
    display: none;
  }
  h2 {
    font: 900 48px/48px 'Raleway', sans-serif;
    margin: 0 0 20px;
  }
  h4 {
    font: 900 22px/28px 'Raleway', sans-serif;
  }
  .homePage .blackBlock h2 {
    margin: 7px 0;
  }
  .header {
    height: 60px;
  }
  .book__slider {
    padding-top: 60px;
  }
  .logo {
    margin: auto 0 11px;
    width: 100px;
  }
  .leftSide, .rightSide {
    width: 100%;
    line-height: 0;
  }
  .rightSide {
    z-index: 1;
  }
  .rightSide .slide {
    padding: 60px 25px 20px 24px;
  }
  .slideOrder {
    margin: 0 0 5px;
  }
  .slider .slideOrder {
    font: 900 20px/24px 'Raleway', sans-serif;
  }
}

@media (max-width: 768px) {
  .header {
    height: 60px;
  }
  .logo {
    margin: auto 0 11px;
    width: 100px;
  }
  .bioPage, .partnerPage, .is-zoomed-out .smallerFrameBlock {
    margin-top: 60px;
  }
  .book__slider {
    padding-top: 60px;
  }
  .ourCollective {
    margin: 0 -1.4167% -80px;
  }
  .ourCollective > div {
    flex: 0 0 30.5%;
    margin: 0 1.4167% 80px;
  }
  .leftSide, .rightSide {
    width: 100%;
    line-height: 0;
  }
  .rightSide {
    z-index: 1;
  }
  .slideOrder {
    font: 900 36px/36px 'Raleway', sans-serif;
  }
  .is-zoomed-out .menuPage > .smallerFrameBlock {
    padding-top: 40px;
  }
  .footer > div {
    flex: 1 1 48%;
  }
}

@media (max-width: 667px) {
  h1 {
    font: 900 75px/75px 'Raleway', sans-serif;
    margin: 0 0 20px;
  }
  .journalPage h1 {
    margin: 0 0 40px;
  }
  p {
    font: 16px/26px 'Raleway', sans-serif;
  }
  .blueTitle {
    font: 900 20px/24px 'Raleway', sans-serif;
  }
  .bigText {
    font: 28px/32px 'Raleway', sans-serif;
    margin: 40px 0 20px;
  }
  blockquote {
    font: normal 28px/34px 'Raleway', sans-serif;
  }
  .blockRightSide img.bigImg {
    margin: 40px auto;
  }
  .blockGroup:not(:last-child) {
    margin: 0 0 64px;
  }
  ol.ourValuesList {
    margin: 40px 0 0;
  }
  .ourCollective {
    margin: 0 -1.5% -80px;
  }
  .ourCollective > div {
    flex: 0 0 47%;
    margin: 0 1.5% 80px;
  }
  .homePage .blackBlock > div, .homePage .greyBlock > div {
    padding: 0 25px 0 24px;
  }
  .menu_title {
    font: 900 32px/48px 'Raleway', sans-serif;
  }
  .page-item_title {
    top: -64px;
  }
  .page_title {
    font: 900 32px/48px 'Raleway', sans-serif;
  }
  .book--zoom-out .book__slider {
    padding: 260px 50px 0 48px;
  }
  .book--zoom-out .book__slider {
    height: 970px;
  }
}

@media (max-width: 667px) and (orientation: landscape){
  h1 {
    font: 900 60px/60px 'Raleway', sans-serif;
  }
}

@media (max-width: 568px) {
  .footer > div, /*.footer > div.locationsGroup,*/ .bioBlock .bioPhoto, .bioBlock .bioAbout {
    flex: 1 1 100%;
  }
  ol.ourValuesList li > div  {
    font: 900 20px/25px 'Raleway', sans-serif;
  }
  ol.ourValuesList li::before {
    font: 900 20px/28px 'Raleway', sans-serif;
  }
  ol.ourValuesList li {
    padding: 0 0 0 44px;
  }
  .bioBlock .bioPhoto, .bioAboutText {
    margin: 0;
  }
  .blueGreyBlock {
    background-image: linear-gradient(to bottom, #003BFF 0%,#003BFF 320px, #e6e8eb 0%, #e6e8eb 100%);
  }
  .backBtn {
    top: 20px;
    left: 24px;
  }
  .bioBlock .bioName {
    color: #000;
    margin: 40px 0 20px;
  }
  .bioPage .ourCollective {
    margin-top: 80px;
  }
  .book--zoom-out .book__slider {
    height: 850px;
  }
}

@media (max-width: 568px) and (orientation: landscape){
  h2 {
    font: 900 40px/40px 'Raleway', sans-serif;
    margin: 0 0 7px;
  }
}

@media (max-width: 414px) {
  h1 {
    font: 900 60px/60px 'Raleway', sans-serif;
  }
  .articlePage h1 {
    margin: 0 0 20px;
  }
  .articlePage h1, .postsList .postTitle {
    font: 900 36px/36px 'Raleway', sans-serif;
  }
  .slide h2 {
    font: 900 36px/36px 'Raleway', sans-serif;
    margin: 0 0 20px;
  }
  .homePage h4 {
    font: 900 20px/30px 'Raleway', sans-serif;
  }
  .redText {
    font: 900 16px/29px 'Raleway', sans-serif;
  }
  .locationsGroup > div {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-flex-direction: column;
    flex-direction: column;
  }
  .ourPartners {
    margin: 0 0 -20px;
    justify-content: space-between;
  }
  .ourPartners > div {
    flex: 0 0 48.47%;
    margin: 0 0 20px;
  }
  .ourCollective {
    margin: 0 0 -80px;
  }
  .ourCollective > div {
    flex: 0 0 100%;
    margin: 0 0 80px
  }
  .ourCollective .bioName {
    font: 900 36px/40px 'Raleway', sans-serif;
  }
  .articleImageInner.smallImg, .posts .leftSide {
    background-size: cover;
    height: calc(0.214*100vh);
    max-height: calc(0.214*100vh);
  }
  .rightSide .slide {
    padding: 100px 25px 40px 24px;
  }
  .book--zoom-out .book__slider {
    height: 715px;
  }
  .is-zoomed-out .page_item {
    width: calc(100% - 10px);
    margin-bottom: 120px;
  }
  .partnerPage .articleImageInner {
    height: 21.4vh;
  }
}
@media (max-width: 375px) {
  /*.is-zoomed-out .menuPage h1, .is-zoomed-out .menuPage h2 {
    font: 900 50px/50px 'Raleway', sans-serif;
  }*/
}
@media (max-width: 360px) {
  .ourCollective .bioName, .bioBlock .bioName {
    font: 900 24px/26px 'Raleway', sans-serif;
  }
  h1 {
    font: 900 50px/50px 'Raleway', sans-serif;
  }
  h2 {
    font: 900 40px/40px 'Raleway', sans-serif;
  }
  .articlePage h1, .postsList .postTitle {
    font: 900 30px/30px 'Raleway', sans-serif;
  }
  /*.is-zoomed-out .menuPage h1, .is-zoomed-out .menuPage h2 {
    font: 900 47px/47px 'Raleway', sans-serif;
  }*/
}
@media (max-width: 320px) {
  /*h1, .is-zoomed-out .menuPage h1, .is-zoomed-out .menuPage h2 {
    font: 900 40px/40px 'Raleway', sans-serif;
  }*/
  h2 {
    font: 900 36px/36px 'Raleway', sans-serif;
  }
  blockquote {
    font: normal 24px/28px 'Raleway', sans-serif;
  }
  .nextArticle h2 {
    font: 900 30px/30px 'Raleway', sans-serif;
  }
  .mouse_wrap {
    bottom: 40px;
  }
}

.animation__container--hidden {
  display: none;
}
.animation__container--active {
  display: block;
}

.animation__container .view__page {
  transform: scale(.3) perspective(600px);
  opacity: 0;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;
}

.animation__container .view__page.view__page--active {
  transform: scale(1) perspective(600px);
  opacity: 1;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  min-height: calc(100vh - 90px);
  background-color: #fff;
}

@media (max-width: 768px) {
  .animation__container .view__page.view__page--active {
    min-height: calc(100vh - 60px);
  }
}

.journalPage--article-background {
  position: absolute;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  background: #003BFF;
  height: 100vh;
  overflow: hidden;
  transition: height .3s cubic-bezier(.7, 0, .3, 1);
}

@media (min-width: 414px) {
  .journalPage--article-expanded .posts {
    width: 200%; /* TODO: causes picture to go outside of the screen at 414 < resolution < 835 */
  }

  .journalPage--article-expanded .rightSide {
    display: none;
  }

  .journalPage--article-background {
    display: none;
  }
}

@media (max-width: 414px) {
 .journalPage--article-expanded .posts {
    padding: 0 0 0 100%; /* TODO: causes picture to disappear on iPhone 6/7/8 specifically */
    height: calc(100vh - 59px);
  }

  .journalPage--article-background {
    display: block;
  }
}

.journalPage--article-expanded-completed .posts {
  height: 0;
  min-height: 0;
}

@media (min-width: 414px) {
  .journalPage--article-expanded-completed .posts {
    height: 35vh;
    min-height: 35vh;
  }

  .articlePage .articleImageInner {
    display: none;
  }
}

.journalPage--article-expanded-completed .posts .postImage {
  height: 35vh;
}

.journalPage .backBtn {
  z-index: 9;
}

.innerPageBackgroundLoader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0;
  pointer-events: none;
  background: #003BFF;
  z-index: 9;
  overflow: hidden;
  visibility: hidden;
  transform: translateY(100%);
}

.innerPage--loading-enter .innerPageBackgroundLoader {
  transform: translateY(0%);
  opacity: 1;
  height: 1000%;
  visibility: visible;
  transition: transform 1s cubic-bezier(.7,0,.3,1);
}

.innerContentBackgroundLoaderTop {
  pointer-events: none;
  background: #003BFF;
  height: 0;
  width: 100vw;
}

.innerPage--loading-active .innerContentBackgroundLoaderTop {
  height: 100vh;
}

.innerPage--loading-exit .innerContentBackgroundLoaderTop {
  height: 0;
  transition: height .5s cubic-bezier(.7,0,.3,1);
}

.innerPage--loading-enter .backBtn,
.innerPage--loading-active .backBtn,
.innerPage--loading-exit .backBtn {
  display: none;
}

@media (max-width: 414px) {
  .book__slider .page_item h1 {
    font-size: 56px;
  }

  #home h2 {
    font-size: 52px;
  }
}

.is-tile-expanded {
  overflow: hidden;

}

.is-tile-expanded--complete .book,
.is-tile-expanded--complete .preview-footer,
.is-tile-expanded--complete .book .postImageInner {
  visibility: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.menuPage--isIos .animation__container .homePage .sectionContent {
  margin-bottom: 90px;
  position: relative;
}

.menuPage--isIos .animation__container .mouse_wrap {
  bottom: -60px;
}
